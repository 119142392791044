// src/components/Features.jsx
import React from 'react';
import { motion } from 'framer-motion';

const features = [
    {
        title: "Smart Search",
        description: "Instantly search through your chat history with powerful filters and organization. Find any conversation, prompt, or response in seconds with our intelligent search system."
    },
    {
        title: "Folder Management",
        description: "Organize your chats and prompts into custom folders for easy access. Create a structured workspace that matches your workflow and never lose track of important conversations."
    },
    {
        title: "Favorites",
        description: "Save your most important chats and prompts for quick reference. Mark conversations and responses as favorites to build your personal knowledge base of AI interactions."
    },
    {
        title: "Instant Sync",
        description: "Seamlessly sync your data across devices in real-time. Access your organized AI conversations from anywhere, with all your folders, favorites, and settings instantly available."
    }
];

const FeatureCard = ({ title, description }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="bg-gray-800 rounded-3xl overflow-hidden p-8 flex flex-col space-y-6"
    >
        <h3 className="text-2xl font-bold text-gradient">
            {title}
        </h3>
        <p className="text-gray-400 text-lg leading-relaxed">
            {description}
        </p>
        <div className="relative rounded-2xl overflow-hidden bg-gradient-to-br from-blue-400/10 via-purple-400/10 to-orange-400/10">
            <div className="aspect-[16/9] relative">
                <img
                    src="/api/placeholder/800/450"
                    alt={title}
                    className="w-full h-full object-cover rounded-2xl"
                />
                {/* Expand button */}
                <button className="absolute top-4 right-4 p-2 bg-gray-900/50 backdrop-blur-sm rounded-lg border border-gray-700/50 hover:bg-gray-900/75 transition-colors">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gradient" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                    </svg>
                </button>
            </div>
        </div>
    </motion.div>
);

const Features = () => {
    return (
        <section id="features" className="relative py-24 bg-black">
            {/* Grid Pattern Background */}
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `
            linear-gradient(#232731 1px, transparent 1px),
            linear-gradient(to right, #232731 1px, transparent 1px)
          `,
                    backgroundSize: '50px 50px'
                }}
            />

            <div className="container mx-auto px-6 relative z-10">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    viewport={{once: true}}
                    className="text-center mb-20"
                >
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                        Your Time Is Precious
                    </h2>
                    <p className="text-gray-400 text-xl max-w-3xl mx-auto">
                        Stop wasting time searching through endless chat histories. Experience the power of organized AI
                        conversations with RingGPT.
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;