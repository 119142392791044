import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TypingEffect = ({ texts }) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [displayText, setDisplayText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const text = texts[currentTextIndex];

        if (isDeleting) {
            if (displayText === '') {
                setIsDeleting(false);
                setCurrentTextIndex((prev) => (prev + 1) % texts.length);
            } else {
                const timeout = setTimeout(() => {
                    setDisplayText(prev => prev.slice(0, -1));
                }, 50);
                return () => clearTimeout(timeout);
            }
        } else {
            if (displayText === text) {
                const timeout = setTimeout(() => {
                    setIsDeleting(true);
                }, 2000);
                return () => clearTimeout(timeout);
            } else {
                const timeout = setTimeout(() => {
                    setDisplayText(text.slice(0, displayText.length + 1));
                }, 150);
                return () => clearTimeout(timeout);
            }
        }
    }, [displayText, isDeleting, currentTextIndex, texts]);

    return (
        <motion.span
            className="text-gradient font-bold text-6xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            {displayText}
            <motion.span
                animate={{ opacity: [0, 1] }}
                transition={{ repeat: Infinity, duration: 0.7 }}
                className="inline-block ml-1"
            >
                |
            </motion.span>
        </motion.span>
    );
};

export default TypingEffect;