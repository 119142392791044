import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const scrollToSection = (sectionId) => {
        if (location.pathname !== '/') {
            window.location.href = `/#${sectionId}`;
            return;
        }

        const element = document.getElementById(sectionId);
        if (element) {
            const navbarHeight = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            // Update URL without causing a page refresh
            navigate(`/#${sectionId}`, { replace: true });
        }
    };

    return (
        <footer className="bg-gray-900 border-t border-gray-800">
            <div className="container mx-auto px-6 py-12">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="text-xl font-bold text-gradient mb-4">RingGPT</h3>
                        <p className="text-gray-400">
                            One extension to rule them all. Manage your AI chats efficiently.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gradient mb-4">Product</h4>
                        <ul className="space-y-2">
                            <li>
                                <button
                                    onClick={() => scrollToSection('features')}
                                    className="text-gray-400 hover:text-white"
                                >
                                    Features
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => scrollToSection('pricing')}
                                    className="text-gray-400 hover:text-white"
                                >
                                    Pricing
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gradient mb-4">Legal</h4>
                        <ul className="space-y-2">
                            <li><a href="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</a></li>
                            <li><a href="/terms" className="text-gray-400 hover:text-white">Terms of Service</a></li>
                        </ul>
                    </div>
                </div>
                <div className="border-t border-gray-800 mt-8 pt-8 text-center space-y-2">
                    <p className="text-gray-400">admin@ring-gpt.com</p>
                    <p className="text-gray-400">
                        RingGPT © {new Date().getFullYear()} All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;