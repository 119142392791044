import React from 'react';
import { motion } from 'framer-motion';

const plans = [
    {
        name: "Basic",
        price: 0,
        features: [
            "3 Folders",
            "3 Custom Prompts",
            "3 Favorites",
            "Unlimited Search",
            "Instant Sync",
            "Basic Support"
        ]
    },
    {
        name: "Pro",
        price: 5,
        link: "https://ring-gpt.lemonsqueezy.com/buy/f8aea559-f899-43d0-87ee-7d3b9f59a74b",
        features: [
            "Unlimited Folders",
            "Unlimited Custom Prompts",
            "Unlimited Favorites",
            "Unlimited Search",
            "Priority Support",
            "Early Access to New Features"
        ],
        isPopular: true
    },
    {
        name: "Elite",
        price: 99,
        link: "https://ring-gpt.lemonsqueezy.com/buy/403093f4-40fb-4c15-a1c9-ba9acd61f0db",
        features: [
            "Unlimited Folders",
            "Unlimited Custom Prompts",
            "Unlimited Favorites",
            "Unlimited Search",
            "Priority Support",
            "Early Access to New Features"
        ]
    }
];

const PricingCard = ({ name, price, features, isPopular, link }) => {
    const CardWrapper = link ? motion.a : motion.div;
    const cardProps = link ? {
        href: link,
        target: "_blank",
        rel: "noopener noreferrer"
    } : {};

    return (
        <CardWrapper
            {...cardProps}
            className={`bg-gray-800/80 backdrop-blur-sm p-8 rounded-xl relative ${isPopular ? 'border-2 border-gradient' : ''} ${link ? 'cursor-pointer hover:-translate-y-2 transition-transform duration-300' : ''}`}
        >
            {isPopular && (
                <span className="bg-primary-gradient text-white px-3 py-1 rounded-full text-sm absolute -top-3 right-4">
                    Most Popular
                </span>
            )}
            <h3 className="text-2xl font-bold text-gradient mb-4">{name}</h3>
            <div className="text-4xl font-bold text-gradient mb-6">
                {price === 0 ? 'Free' : `$${price}`}
                {price !== 99 && price !== 0 && '/month'}
                {price === 99 && ' Lifetime'}
            </div>
            <ul className="space-y-3">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-300">
                        <svg className="w-5 h-5 text-gradient mr-2" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        {feature}
                    </li>
                ))}
            </ul>
        </CardWrapper>
    );
};

const Pricing = () => {
    return (
        <section id="pricing" className="relative py-24 bg-black">
            <div className="absolute inset-0 bg-grid-pattern opacity-10" />
            <div className="container mx-auto px-6 relative z-10">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-16"
                >
                    <h2 className="text-4xl font-bold text-gradient mb-4">
                        Simple, Transparent Pricing
                    </h2>
                    <p className="text-gray-400 max-w-2xl mx-auto">
                        Choose the plan that best fits your needs
                    </p>
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
                    {plans.map((plan, index) => (
                        <PricingCard key={index} {...plan} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Pricing;