// src/components/Navbar.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GanttChartSquare } from 'lucide-react';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const scrollToSection = (sectionId) => {
        if (location.pathname !== '/') {
            window.location.href = `/#${sectionId}`;
            return;
        }

        const element = document.getElementById(sectionId);
        if (element) {
            const navbarHeight = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            navigate(`/#${sectionId}`, { replace: true });
        }
    };

    return (
        <nav className="fixed w-full z-50 bg-slate-950">
            <div className="container mx-auto px-6 py-4">
                <div className="flex items-center justify-between">
                    <Link to="/" className="flex items-center space-x-2">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            className="flex items-center space-x-2"
                        >
                            <div className="bg-primary-gradient bg-clip-text">
                                <GanttChartSquare className="w-8 h-8 stroke-[#6366f1]" />
                            </div>
                            <span className="text-2xl font-bold text-gradient">RingGPT</span>
                        </motion.div>
                    </Link>
                    <div className="hidden md:flex items-center space-x-8">
                        <button
                            onClick={() => scrollToSection('features')}
                            className="text-gray-300 hover:text-white"
                        >
                            Features
                        </button>
                        <button
                            onClick={() => scrollToSection('pricing')}
                            className="px-6 py-2 btn-gradient text-white rounded-lg"
                        >
                            Pricing
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;