// src/components/Hero.jsx
import React from 'react';
import { motion } from 'framer-motion';
import TypingEffect from './TypingEffect';

const Hero = () => {
    return (
        <div className="relative py-40 bg-gradient-to-br from-slate-800 via-purple-950 to-black">

            <div className="absolute inset-0 bg-grid-pattern opacity-10" />
            <div className="container mx-auto px-6 text-center">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="max-w-3xl mx-auto"
                >
                    <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">
                        One <span className="text-gradient">Extension</span> To <span
                        className="text-gradient">Rule</span> Them All
                    </h1>
                    <div className="text-xl md:text-2xl text-gray-300 mb-8  mt-8">
                        No hassle Search and Manage chats for
                    </div>
                    <div className="text-xl md:text-2xl text-gray-300 mb-8">
                        {' '}
                        <TypingEffect texts={['ChatGPT', 'Claude.AI', 'Perplexity.AI']}/>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center gap-4 mb-8">
                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            className="px-8 py-3 btn-gradient text-white rounded-lg hover:bg-green-600 transition-colors"
                        >
                            Get Started
                        </motion.button>
                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            className="px-8 py-3 border border-gradient text-gradient rounded-lg hover:btn-gradient hover:text-white transition-colors"
                        >
                            Learn More
                        </motion.button>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Hero;